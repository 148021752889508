<template>
  <div :class="{ 'w-100': isCheckoutPage }">
    <header
      :class="{'hidden-xs': isCheckoutPage}"
      class="modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative"
    >
      <img src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" class="modal-close filter-color" @click="close">
      {{ $t('Reset password') }}
    </header>

    <div
      class="modal-content pb60 px65 cl-secondary"
      :class="[{ 'pt60' : !isMobile }, { 'full-screen-login' : isMobile && !isCheckoutPage }]"
    >
      <img v-show="!isCheckoutPage" src="/assets/svg/Krzyzyk.svg" width="24px" height="24px" alt="krzyzyk" class="modal-close visible-xs" @click="close">
      <div class="flex center-xs pb20 pt140" v-show="isMobile && !isCheckoutPage">
        <i class="icon-ag_grzejniki-logo icon-size cl-white" />
      </div>
      <template v-if="!passwordSent">
        <form @submit.prevent="sendEmail" novalidate>
          <div class="mb20">
            <p
              class="mb45 weight-mobile"
              :class="{ 'cl-white h5 center-xs' : isMobile && !isCheckoutPage }"
            >
              {{ $t('Enter your email to receive instructions on how to reset your password.') }}
            </p>
            <base-input
              type="email"
              :class="{ 'light-version' : isMobile && !isCheckoutPage }"
              name="email"
              v-model="email"
              focus
              :placeholder="$t('E-mail address *')"
              :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: $t('Field is required.')
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
            />
          </div>
          <button-full class="mb35 w-100" type="submit">
            {{ $t('Reset password') }}
          </button-full>
          <div class="center-xs weight-mobile" :class="{'cl-white h5' : isMobile && !isCheckoutPage}">
            {{ $t('or') }}
            <a
              href="#"
              class="link-hover"
              :class="{'cl-white links' : isMobile && !isCheckoutPage}"
              @click.prevent="switchElem"
            >{{ $t('return to log in') }}</a>
          </div>
        </form>
      </template>
      <template v-if="passwordSent">
        <form class="py20">
          <p
            class="py30 mb80"
            :class="{'cl-white h5' : isMobile && !isCheckoutPage}"
          >
            {{ $t("We've sent password reset instructions to your email. Check your inbox and follow the link.") }}
          </p>
          <button-full
            class="mb35 w-100"
            type="link"
            @click.native="switchElem"
          >
            {{ $t('Back to login') }}
          </button-full>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
import { MobileDetected } from 'theme/components/core/mobileDetected.ts'
import ButtonFull from 'theme/components/theme/ButtonFull.vue';
import BaseInput from '../Form/BaseInput.vue';
import { required, email } from 'vuelidate/lib/validators';
import i18n from '@vue-storefront/i18n';
import CurrentPage from 'theme/mixins/currentPage';

export default {
  validations: {
    email: {
      required,
      email
    }
  },
  mixins: [CurrentPage, MobileDetected],
  methods: {
    close () {
      this.$bus.$emit('modal-hide', 'modal-signup');
    },
    sendEmail () {
      // todo: send email with reset password instructions

      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: i18n.t('Please fix the validation errors'),
          action1: { label: i18n.t('OK') }
        });
        return;
      }

      this.$bus.$emit(
        'notification-progress-start',
        i18n.t('Resetting the password ... ')
      );
      this.$store
        .dispatch('user/resetPassword', { email: this.email, websiteId: 1 })
        .then(response => {
          this.$bus.$emit('notification-progress-stop');
          if (response.code === 200) {
            this.passwordSent = true;
          } else {
            this.$store.dispatch('notification/spawnNotification', {
              type: 'error',
              message: i18n.t('Error while sending reset password e-mail'),
              action1: { label: i18n.t('OK'), action: 'close' }
            });
          }
        })
        .catch(err => {
          console.log(err)
          this.$bus.$emit('notification-progress-stop');
        });
    },
    switchElem () {
      this.$store.commit('ui/setAuthElem', 'login');
    },
    submit (response) {
      if (response) {
        this.reCaptchaValid = response;
      }
    },
    responseRecaptcha () {
      this.$refs.recaptcha.getResponse();
    }
  },
  name: 'ForgotPass',
  data () {
    return {
      email: '',
      passwordSent: false,
      reCaptchaValid: ''
    };
  },
  components: {
    ButtonFull,
    BaseInput
  }
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 50px;
}
.link-hover {
  @media (min-width: 767px) {
    color: #828282;
    &:hover {
      text-decoration: underline;
    }
  }
}
.filter-color {
  filter: invert(1);
}
.weight-mobile {
  @media (max-width: 767px) {
    font-weight: 100;
  }
}
.modal-close {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 10px;
  font-size: 24px;
  padding: 15px 25px;
  @media (max-width: 767px) {
    top: 0;
  }
  @media (max-width: 600px) {
    padding: 15px 15px;
  }
}
.modal-content {
  @media (max-width: 400px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.full-screen-login {
  height: 100vh;
  overflow-y: auto;
  max-height: 100vh;
  padding-top: 0px;
  padding-bottom: 0px;
  background: url("/assets/login-bg.png") 0 0 no-repeat;
  background-position: center;
  background-size: cover;
}
.white-filter {
  filter: brightness(0) invert(1);
}
.links {
  text-decoration: underline;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background:transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
</style>
