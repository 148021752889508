var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'w-100': _vm.isCheckoutPage }},[_c('header',{staticClass:"modal-header py15 px65 h2 serif weight-700 center-sm hidden-xs relative",class:{'hidden-xs': _vm.isCheckoutPage}},[_c('img',{staticClass:"modal-close filter-color",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}),_vm._v("\n    "+_vm._s(_vm.$t('Reset password'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-content pb60 px65 cl-secondary",class:[{ 'pt60' : !_vm.isMobile }, { 'full-screen-login' : _vm.isMobile && !_vm.isCheckoutPage }]},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCheckoutPage),expression:"!isCheckoutPage"}],staticClass:"modal-close visible-xs",attrs:{"src":"/assets/svg/Krzyzyk.svg","width":"24px","height":"24px","alt":"krzyzyk"},on:{"click":_vm.close}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile && !_vm.isCheckoutPage),expression:"isMobile && !isCheckoutPage"}],staticClass:"flex center-xs pb20 pt140"},[_c('i',{staticClass:"icon-ag_grzejniki-logo icon-size cl-white"})]),_vm._v(" "),(!_vm.passwordSent)?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail($event)}}},[_c('div',{staticClass:"mb20"},[_c('p',{staticClass:"mb45 weight-mobile",class:{ 'cl-white h5 center-xs' : _vm.isMobile && !_vm.isCheckoutPage }},[_vm._v("\n            "+_vm._s(_vm.$t('Enter your email to receive instructions on how to reset your password.'))+"\n          ")]),_vm._v(" "),_c('base-input',{class:{ 'light-version' : _vm.isMobile && !_vm.isCheckoutPage },attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
              {
                condition: !_vm.$v.email.required && _vm.$v.email.$error,
                text: _vm.$t('Field is required.')
              },
              {
                condition: !_vm.$v.email.email && _vm.$v.email.$error,
                text: _vm.$t('Please provide valid e-mail address.')
              }
            ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_vm._v(" "),_c('button-full',{staticClass:"mb35 w-100",attrs:{"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Reset password'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"center-xs weight-mobile",class:{'cl-white h5' : _vm.isMobile && !_vm.isCheckoutPage}},[_vm._v("\n          "+_vm._s(_vm.$t('or'))+"\n          "),_c('a',{staticClass:"link-hover",class:{'cl-white links' : _vm.isMobile && !_vm.isCheckoutPage},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem($event)}}},[_vm._v(_vm._s(_vm.$t('return to log in')))])])],1)]:_vm._e(),_vm._v(" "),(_vm.passwordSent)?[_c('form',{staticClass:"py20"},[_c('p',{staticClass:"py30 mb80",class:{'cl-white h5' : _vm.isMobile && !_vm.isCheckoutPage}},[_vm._v("\n          "+_vm._s(_vm.$t("We've sent password reset instructions to your email. Check your inbox and follow the link."))+"\n        ")]),_vm._v(" "),_c('button-full',{staticClass:"mb35 w-100",attrs:{"type":"link"},nativeOn:{"click":function($event){return _vm.switchElem($event)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Back to login'))+"\n        ")])],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }